<template>
  <div>
    <div v-if="loading" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('BUSES')})  | capitalize}}</strong>
    </div>
    <b-select
        v-else
        id="bus-input" type="text"
        :state="state"
        :value="content"
        @change="handleInput" class="w-100">
      <b-select-option :value="undefined">{{$t(undefinedMeans, {gender: 'o', catalog: $t('TURNS')}) | capitalize}}</b-select-option>
      <b-select-option :key="item.id" v-for="item in items" :value="valueType === 'id' ? item.id : valueType === 'object' ? item : item._links.self.href">{{$t(item[fieldToDisplay || 'economic'])}}</b-select-option>
    </b-select>
    <b-form-invalid-feedback id="bus-input-feedback">
      <div v-for="error in errors" :key="error.error">
        {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('BUS')},})  | capitalize }}
      </div>
    </b-form-invalid-feedback>
  </div>

</template>

<script>
import {VModel, SendsMessages} from "@/mixins";
import Bus from "@/buses/index";

export default {
  name: "BusSingleSelector",
  props: ['state', 'errors', 'companyId', 'valueType', 'undefinedMeans', "fieldToDisplay"],
  mixins: [VModel, SendsMessages],
  methods: {
    async loadBuses() {
      try {
        this.loading = true;
        this.items = await Bus.findAll({perPage: 1000, sort: 'economic', filter: {status: true}});
      } catch(e) {
        this.sendError('ERROR_LOADING_CATALOG', {}, e);
      } finally {
        this.loading = false;
      }
    }
  },
  async mounted() {
    await this.loadBuses();
  },
  data() {
    return {
      loading: false,
      items: []
    }
  }
}
</script>

<style scoped>

</style>
