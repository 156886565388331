<template>
  <div>
    <b-form inline @submit.prevent="query">
      <div class="text-left w-25 p-1">
        <label class="d-block">{{$t('SEARCH_FROM_DATE_LABEL') | capitalize}}</label>
        <b-datepicker class="w-100" :state="state('from')" v-model="$v.form.from.$model"></b-datepicker>
      </div>

      <div class="text-left w-25 p-1">
        <label class="d-block">{{$t('SEARCH_TO_DATE_LABEL') | capitalize}}</label>
        <b-datepicker class="w-100" :state="state('to')" v-model="$v.form.to.$model"></b-datepicker>
      </div>

      <div class="w-25 p-1">
        <label class="d-block">{{$t('COMPANY_FILTER_LABEL') | capitalize}}</label>
        <company-single-selector @instance="setCompany" class="w-100 mb-2" v-model="$v.form.companyId.$model" value-type="id"
                                 :undefined-means="$t('ALL_COMPANIES_LABEL') | capitalize"></company-single-selector>
      </div>

      <div class="w-25 p-1">
        <label class="d-block">{{$t('BUS_FILTER_LABEL') | capitalize}}</label>
        <bus-single-selector :field-to-display="fieldToDisplayBusses" class="w-100 mb-2" v-model="$v.form.busId.$model" value-type="id"
                                :undefined-means="$t('ALL_BUSSES_LABEL') | capitalize"></bus-single-selector>
      </div>

      <div class="w-25 p-1">
        <label class="d-block">{{$t('TRAVEL_PAID_LABEL') | capitalize}}</label>
        <b-select v-model="$v.form.paid.$model">
          <b-select-option :value="undefined">{{$t('TRAVEL_ALL_LABEL') | capitalize}}</b-select-option>
          <b-select-option :value="true">{{$t('TRAVEL_IS_PAID_LABEL') | capitalize}}</b-select-option>
          <b-select-option :value="false">{{$t('TRAVEL_NOT_PAID_LABEL') | capitalize}}</b-select-option>
        </b-select>
      </div>

      <b-button type="submit" variant="primary">
        <b-icon-circle-fill animation="throb" v-if="loading"></b-icon-circle-fill>
        <b-icon-search v-else></b-icon-search>
        {{ $t('QUERY_LABEL') | capitalize }}
      </b-button>
    </b-form>

    <div v-if="data && data.length > 0" class="mt-2" style="height: 800px !important;">
      <h1 class="text-center">{{ $t('BUS_REPORT_TITLE', {general: company ? company.name : $t('BUS_REPORT_TITLE_GENERAL')}) }}</h1>
      <h2 class="text-center">{{ $t('SEARCH_FROM_DATE_LABEL') }} {{ form.from | date }} {{ $t('SEARCH_TO_DATE_LABEL') }}
        {{ form.to | date }}</h2>
      <b-table style="height: 800px !important;" :sticky-header="true" custom-foot striped hover :fields="fields" :items="data">
        <template #head(floatNumber)>
          {{ $t('BUS_REPORT_FLOAT_NUMBER_LABEL') | capitalize }}
        </template>
        <template #head(economic)>
          {{ $t('BUS_REPORT_ECONOMIC_LABEL') | capitalize }}
        </template>
        <template #head(route)>
          {{ $t('BUS_REPORT_ROUTE_LABEL') | capitalize }}
        </template>
        <template #head(monday)>
          {{ $t('BUS_REPORT_MONDAY_LABEL') | capitalize }}
        </template>
        <template #head(tuesday)>
          {{ $t('BUS_REPORT_TUESDAY_LABEL') | capitalize }}
        </template>
        <template #head(wednesday)>
          {{ $t('BUS_REPORT_WEDNESDAY_LABEL') | capitalize }}
        </template>
        <template #head(thursday)>
          {{ $t('BUS_REPORT_THURSDAY_LABEL') | capitalize }}
        </template>
        <template #head(friday)>
          {{ $t('BUS_REPORT_FRIDAY_LABEL') | capitalize }}
        </template>
        <template #head(saturday)>
          {{ $t('BUS_REPORT_SATURDAY_LABEL') | capitalize }}
        </template>
        <template #head(sunday)>
          {{ $t('BUS_REPORT_SUNDAY_LABEL') | capitalize }}
        </template>
        <template #head(total)>
          {{ $t('BUS_REPORT_TOTAL_LABEL') | capitalize }}
        </template>

        <!-- Default fall-back custom formatted footer cell -->
        <template #custom-foot>
          <tr>
            <td colspan="10" class="text-right"><b>{{ $t('BUS_REPORT_TOTAL_LABEL') | capitalize }}</b></td>
            <td>{{ total }}</td>
          </tr>
        </template>
      </b-table>
    </div>
    <div v-else>
      <h1 class="text-center">{{$t('REPORTS_NO_DATA') | capitalize}}</h1>
    </div>
  </div>

</template>

<script>
import {CapitalFilter, DateFilter, Form, SendsMessages} from "@/mixins";
import {required} from "vuelidate/lib/validators";
import Reports from "@/reports/index";
import moment from "moment";
import CompanySingleSelector from "@/companies/CompanySingleSelector";
import BusSingleSelector from "@/buses/BusSingleSelector";

export default {
  name: "BusReport",
  components: {BusSingleSelector, CompanySingleSelector},
  mixins: [CapitalFilter, SendsMessages, Form, DateFilter],
  data() {
    return {
      fieldToDisplayBusses: 'economic',
      loading: false,
      data: [],
      company: undefined,
      fields: ['floatNumber', 'economic', 'route', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'total'],
      form: {
        from: new Date(),
        to: new Date(),
        companyId: undefined,
        busId: undefined
      }
    }
  },
  methods: {
    setCompany(val) {
      console.log(val);
      this.company = val;
    },
    async query() {
      try {
        this.$v.form.$touch();
        if (this.$v.form.$invalid) {
          return;
        }
        this.loading = true;
        this.data = [];
        const resp = await Reports.bus(this.form);
        this.data = resp.data;
      } catch (e) {
        this.sendError('ERROR_LOADING_REPORT_BUS', {}, e);
      } finally {
        this.loading = false;
      }
    }
  },
  computed: {
    total() {
      if (this.data && this.data.length > 0) {
        return this.data.map(x => x.total).reduce((a, b) => a + b);
      } else {
        return 0;
      }
    }
  },
  validations: {
    form: {
      from: {required},
      to: {
        required, min: (value, vm) => {
          return moment(value).toDate() >= moment(vm.from).toDate();
        }
      },
      companyId: {},
      busId: {},
      paid: {}
    }
  }
}
</script>

<style scoped>
.b-table-sticky-header {
  max-height: 900px !important;
  height: 800px !important;
}
</style>