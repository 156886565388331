import http from "@/http";
import eventBus from "@/events";

const Bus = {
    findAll: async function (ctx) {
        if(ctx) {
            const page = ctx.currentPage - 1 || 0;
            const size = ctx.perPage || 20;
            const sortDesc = ctx.sortDesc ? ',desc' : '';
            const sort = (ctx.sortBy || 'economic') + sortDesc;
            const filter = ctx.filter ? ctx.filter : {};
            const pagination = {page, size, sort, sortDesc};
            const params = {...filter, ...pagination};
            const resp = await http("/buses/search/all", {params});
            eventBus.$emit('LIST_COUNT_BUS_EVENT', resp.data.page.totalElements);
            return resp.data._embedded.buses;
        } else {
            const resp = await http("/buses/search/all");
            eventBus.$emit('LIST_COUNT_BUS_EVENT', resp.data.page.totalElements);
            return resp.data._embedded.buses;
        }
    },
    create: function(data) {
        return http.post('/buses', data);
    },
    update: function (id, data) {
        return http.patch('/buses/' + id, data);
    },
    findById(id) {
        return http.get('/buses/' + id);
    },
    enable(id) {
        return http.patch('/buses/' + id + '/enable')
    },
    disable(id) {
        return http.patch('/buses/' + id + '/disable')
    },
    deleteById(id) {
        return http.delete('/buses/' + id)
    },
    findCompany: function(id) { return http.get('undefined'.replace('{id}', id)); },

    economicExists(economic) {
        return http.get("/buses/exists/economic", {params: {economic: economic}}).then(x => {
            return !x.data;
        });
    },

    floatNumberExists(floatNumber) {
        return http.get("/buses/exists/floatNumber", {params: {floatNumber: floatNumber}}).then(x => {
            return !x.data;
        });
    },

    seriesExists(series) {
        return http.get("/buses/exists/series", {params: {series: series}}).then(x => {
            return !x.data;
        });
    },

    platesExists(plates) {
        return http.get("/buses/exists/plates", {params: {plates: plates}}).then(x => {
            return !x.data;
        });
    },
};

export default Bus;
